<template>
  <section class="customContainer">
    <slot />
    <YmiNotifications :mapped-store="notieStore" />
  </section>
</template>

<script setup>
import { useNotieStore } from '~/stores/notifications';

const notieStore = useNotieStore();
</script>
